import React, { useState } from 'react';
import { auth, database } from '../firebase'; // Import Firebase auth and database
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { useNavigate } from 'react-router-dom';

const StudioProfile = () => {
  const [studioName, setStudioName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional user data in Realtime Database
      await set(ref(database, 'users/' + user.uid), {
        studioName,
        email,
        userType: 'studio', // Store user type
        signedUp: false, // Set signedUp to false
      });

      console.log('Studio profile submitted successfully:', user);
      navigate('/studio-typeform', { state: { userId: user.uid } }); // Update this line to navigate to StudioTypeform with user ID
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Sign-up failed: ' + error.message); // Display error message to the user
    }
  };

  return (
    <div>
      <h2>Game Studio Profile Setup</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Studio Name" value={studioName} onChange={(e) => setStudioName(e.target.value)} required />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default StudioProfile;
