import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignUpSelection.css';
import continueIcon from '../assets/continue-icon.svg'; // Import the SVG icon

const SignUpSelection = () => {
  const [selectedOption, setSelectedOption] = useState(null); // State to track selected option
  const navigate = useNavigate(); // Hook for navigation

  const handleButtonClick = (option) => {
    // Toggle selection
    if (selectedOption === option) {
      setSelectedOption(null); // Deselect if already selected
    } else {
      setSelectedOption(option); // Select the new option
    }
  };

  const handleContinue = () => {
    if (selectedOption === 'creator') {
      navigate('/signup-creator'); // Navigate to Creator Profile
    } else if (selectedOption === 'studio') {
      navigate('/signup-studio'); // Navigate to Studio Profile
    }
  };

  return (
    <div className='signup-selection'>
      <h1>Choose Your Path</h1>
      <p>
        Is there a difference? <Link to="https://newgame.me/#faq">Check the FAQ</Link>
      </p>
      <div className="button-container">
        <button
          onClick={() => handleButtonClick('creator')} 
          className={selectedOption === 'creator' ? 'selected' : ''}
        >
          CREATOR
        </button>
        <button
          onClick={() => handleButtonClick('studio')} 
          className={selectedOption === 'studio' ? 'selected' : ''}
        >
          STUDIO
        </button>
      </div>
    <button 
      className="continue-path-button" 
      onClick={handleContinue} 
      disabled={!selectedOption}
      >
      <div className="text-wrapper">CONTINUE</div>
      <img src={continueIcon} alt="Continue" className="icon" /> {/* Use the SVG icon */}
    </button>
  </div>
  );
};

export default SignUpSelection;
