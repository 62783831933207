import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './HeaderMobile.css'; // Import mobile-specific styles

const HeaderMobile = () => {
    const location = useLocation(); // Get the current location
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(prevState => !prevState); // Toggle dropdown state
    };

    // Check if the current path is the SignUpSelection page
    const isSignUpSelection = location.pathname === '/';

    return (
        <div className={`header-mobile ${isSignUpSelection ? 'signup-header' : ''}`}>
            <div className="banner" onClick={toggleDropdown}>
                <img src={logo} alt="Logo" className="header-logo" />
            </div>
            {dropdownOpen && ( // Render dropdown content only if open
                <div className="dropdown-content">
                    <a href="https://newgame.me/agency" className="nav-link">Agency</a>
                    <a href="https://newgame.me/docs" target="_blank" rel="noopener noreferrer" className="nav-link">Docs</a>
                    <a href="./login" target="_blank" rel="noopener noreferrer" className="nav-button">Sign In</a>
                </div>
            )}
        </div>
    );
};

export default HeaderMobile;
