import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignUpSelection from './components/SignUpSelection';
import CreatorProfile from './components/CreatorProfile';
import StudioProfile from './components/StudioProfile';
import Header from './components/Header'; // Import the standard header
import CreatorTypeform from './components/CreatorTypeform';
import StudioTypeform from './components/StudioTypeform'; // Import the new component
import Login from './components/Login';
import CreatorPage from './components/CreatorPage'; // Import the new component
import StudioPage from './components/StudioPage'; // Import your StudioPage component
import HeaderMobile from './components/HeaderMobile'; // Import the mobile Header component

const App = () => {
	const isMobile = window.innerWidth < 810; // Check if the screen width is less than 810px

	return (
		<div className="app">
			{isMobile ? <HeaderMobile /> : <Header />} {/* Render mobile or standard header based on screen size */}
			<Routes>
				<Route path="/" element={<SignUpSelection />} />
				<Route path="/signup-creator" element={<CreatorProfile />} />
				<Route path="/signup-studio" element={<StudioProfile />} />
				<Route path="/creator-typeform" element={<CreatorTypeform />} />
				<Route path="/studio-typeform" element={<StudioTypeform />} />
				<Route path="/login" element={<Login />} />
				<Route path="/studio-page/:userId" element={<StudioPage />} />
				<Route path="/creator-page/:userId" element={<CreatorPage />} />
			</Routes>
		</div>
	);
};

export default App;