import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getDatabase } from "firebase/database"; // Import getDatabase
import { getStorage } from "firebase/storage"; // Import getStorage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAh7GQRd7U2yKm-DjloyoxRU0sbuLQRTXU",
  authDomain: "new-game-sign-up.firebaseapp.com",
  databaseURL: "https://new-game-sign-up-default-rtdb.firebaseio.com",
  projectId: "new-game-sign-up",
  storageBucket: "new-game-sign-up.appspot.com",
  messagingSenderId: "797376101381",
  appId: "1:797376101381:web:ae3ec7377f3407beb5706c",
  measurementId: "G-ER0N9E1SQW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Auth
const database = getDatabase(app); // Initialize Firebase Database
const storage = getStorage(app); // Initialize Firebase Storage

// Test Firebase Initialization
console.log("Firebase initialized successfully:", app);

export { app, analytics, auth, database, storage }; // Export auth, database, and storage
