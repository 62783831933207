import React, { useState } from 'react'; // Remove useEffect from here
import { auth, database } from '../firebase'; // Import Firebase auth and database
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import './CreatorProfile.css'; // Ensure this line is present

const CreatorProfile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional user data in Realtime Database
      await set(ref(database, 'users/' + user.uid), {
        name,
        email,
        userType: 'creator', // Store user type
        signedUp: false, // Set signedUp to false
      });

      console.log('Creator profile submitted successfully:', user);
      navigate('/creator-typeform', { state: { userId: user.uid } }); // Pass user ID
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Sign-up failed: ' + error.message); // Display error message to the user
    }
  };

  return (
    <div className="creator-profile">
      <h2>Creator Profile Setup</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="input"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          className="input"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          className="input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          className="input"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit" className="button">Submit</button>
      </form>
    </div>
  );
};

export default CreatorProfile;
