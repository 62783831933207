import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ref, update } from "firebase/database"; // Import Firebase functions
import { database } from '../firebase'; // Import your Firebase database
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component

const StudioTypeform = () => {
  const location = useLocation();
  const { userId: userIdFromState } = location.state || { userId: null }; // Default to null if not available
  
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [studioWebsite, setStudioWebsite] = useState('');
  const [studioSocialAccounts, setStudioSocialAccounts] = useState('');
  const [studioBio, setStudioBio] = useState('');
  const [gameTitle, setGameTitle] = useState('');
  const [teamDetails, setTeamDetails] = useState('');

  const questions = [
    { question: "What is your studio's website?", answer: studioWebsite, setAnswer: setStudioWebsite },
    { question: "What are your studio's social accounts?", answer: studioSocialAccounts, setAnswer: setStudioSocialAccounts },
    { question: "Tell us about your studio.", answer: studioBio, setAnswer: setStudioBio },
    { question: "What is the game title?", answer: gameTitle, setAnswer: setGameTitle },
    { question: "Tell us about your team.", answer: teamDetails, setAnswer: setTeamDetails },
  ];

  const validateData = () => {
    if (currentQuestion === 0 && !studioWebsite) {
      alert("Please fill in your studio's website.");
      return false;
    }
    if (currentQuestion === 1 && !studioSocialAccounts) {
      alert("Please fill in your studio's social accounts.");
      return false;
    }
    if (currentQuestion === 2 && !studioBio) {
      alert("Please fill in your studio bio.");
      return false;
    }
    if (currentQuestion === 3 && !gameTitle) {
      alert("Please enter the game title.");
      return false;
    }
    if (currentQuestion === 4 && !teamDetails) {
      alert("Please tell us about your team.");
      return false;
    }
    return true; // All validations passed
  };

  const handleNext = async (e) => {
    e.preventDefault();
    if (!validateData()) return;

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit(e); // Call handleSubmit
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userData = {
        studioWebsite,
        studioSocialAccounts,
        studioBio,
        gameTitle,
        teamDetails,
      };

      await update(ref(database, 'users/' + userIdFromState), userData); // Update all fields
      console.log('User data updated successfully:', userData);
      navigate(`/studio-page/${userIdFromState}`); // Navigate to the user's profile page
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Failed to update user data: ' + error.message);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Building...</p>
        </div>
      ) : (
        <>
          <h2>{questions[currentQuestion].question}</h2>
          <form onSubmit={handleNext}>
            <input
              type="text"
              value={questions[currentQuestion].answer || ''}
              onChange={(e) => questions[currentQuestion].setAnswer(e.target.value)}
              required
            />
            <button type="submit">
              {currentQuestion < questions.length - 1 ? "Continue" : "Build My Studio Profile"}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default StudioTypeform;
