import React, { useEffect, useState } from 'react';
import { ref, onValue, update } from "firebase/database"; // Import update function
import { database } from '../firebase'; // Import your Firebase database
import { useParams } from 'react-router-dom'; // Import useParams
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component

const StudioPage = () => {
  const { userId } = useParams(); // Get userId from URL parameters
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const userRef = ref(database, 'users/' + userId);
    const unsubscribe = onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
        // Update signedUp property to true
        update(ref(database, 'users/' + userId), { signedUp: true });
      } else {
        console.error('No data found for user:', userId);
      }
      setLoading(false); // Set loading to false after data is fetched
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userId]);

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
        <p>Loading...</p>
      </div>
    ); // Show loading state while fetching data
  }

  return (
    <div className="studio-page">
      <div className="profile-info">
        <h2>{userData.studioName}</h2>
        <p><strong>Email:</strong> {userData.email}</p>
        <p><strong>Bio:</strong> {userData.studioBio}</p>
        <p><strong>Social Accounts:</strong> {userData.studioSocialAccounts}</p>
        <p><strong>Website:</strong> <a href={userData.studioWebsite} target="_blank" rel="noopener noreferrer">{userData.studioWebsite}</a></p>
        <p><strong>Game Title:</strong> {userData.gameTitle}</p>
        <p><strong>Team Details:</strong> {userData.teamDetails}</p>
      </div>
    </div>
  );
};

export default StudioPage;
