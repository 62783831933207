import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from 'react-slick'; // Ensure Slider is used
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CreatorTypeform.css';
import { ref, update } from "firebase/database"; // Import Firebase functions
import { database } from '../firebase'; // Import your Firebase database
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component

const genres = [
  "Adventure", "Action", "Sports", "Simulation", "Platformer",
  "RPG", "First-person shooter", "Action-adventure", "Fighting",
  "Real-time strategy", "Racing", "Shooter", "Puzzle", "Casual",
  "Strategy game", "Massively multiplayer online role-playing", 
  "Stealth", "Party", "Action RPG", "Tactical role-playing", 
  "Survival", "Battle Royale"
];

const CreatorTypeform = () => {
  const location = useLocation();
  const { userId: userIdFromState } = location.state || { userId: null };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [displayName, setDisplayName] = useState('');
  const [genrePreference, setGenrePreference] = useState([]);
  const [contentPlatforms, setContentPlatforms] = useState('');
  const [locationAnswer, setLocation] = useState('');
  const [language, setLanguage] = useState('');
  const [creatorBio, setCreatorBio] = useState('');
  const [featureContent, setFeatureContent] = useState(''); // New state for feature content
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate();

  // Check for userId at the top level after hooks
  if (!userIdFromState) {
    console.error('User ID is undefined');
    return <div>Error: User ID is not available.</div>;
  }

  const questions = [
    { question: "What should we call you?", answer: displayName, setAnswer: setDisplayName },
    { question: "What game genres do you like?", answer: genrePreference, setAnswer: setGenrePreference, isCarousel: true },
    { question: "Where do you create content?", answer: contentPlatforms, setAnswer: setContentPlatforms },
    { question: "Where are you located?", answer: locationAnswer, setAnswer: setLocation },
    { question: "What languages do you speak?", answer: language, setAnswer: setLanguage },
    { question: "Let's set up your shareable profile, share a bit about yourself and complete your bio!", answer: creatorBio, setAnswer: setCreatorBio },
    { question: "Feature your best content on your profile! Add up to 5 links.", answer: featureContent, setAnswer: setFeatureContent }, // New question
  ];

  const validateData = () => {
    if (currentQuestion === 0 && !displayName) {
      alert("Please fill in your display name.");
      return false;
    }
    if (currentQuestion === 1 && genrePreference.length === 0) {
      alert("Please select at least one genre.");
      return false;
    }
    if (currentQuestion === 3 && !locationAnswer) {
      alert("Please fill in your location.");
      return false;
    }
    if (currentQuestion === 4 && !language) {
      alert("Please fill in your language.");
      return false;
    }
    if (currentQuestion === 6) { // Validate the new question
      const urls = featureContent.split(',').map(url => url.trim());
      if (urls.length > 5) {
        alert("Please add up to 5 links only.");
        return false;
      }
      for (let url of urls) {
        if (!isValidURL(url)) {
          alert("Please enter valid URLs.");
          return false;
        }
      }
    }
    return true; // All validations passed
  };

  const isValidURL = (string) => {
    const res = string.match(/(https?:\/\/[^\s]+)/g);
    return (res !== null);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    if (!validateData()) return;

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit(e); // Call handleSubmit
    }
  };

  const handleGenreSelect = (genre) => {
    setGenrePreference((prev) => {
      if (prev.includes(genre)) {
        return prev.filter((g) => g !== genre);
      } else {
        return [...prev, genre];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userData = {
        displayName,
        genrePreference,
        contentPlatforms,
        location: locationAnswer,
        language,
        creatorBio,
        featureContent, // Include feature content in user data
      };

      await update(ref(database, 'users/' + userIdFromState), userData);
      console.log('User data updated successfully:', userData);
      navigate(`/creator-page/${userIdFromState}`);
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Failed to update user data: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Building...</p>
        </div>
      ) : (
        <>
          <h2>{questions[currentQuestion].question}</h2>
          <form onSubmit={handleNext}>
            {currentQuestion === 1 ? ( // Use Slider for genre selection
              <Slider {...settings}>
                {genres.map((genre) => (
                  <div key={genre} className={`genre-card ${genrePreference.includes(genre) ? 'selected' : ''}`} onClick={() => handleGenreSelect(genre)}>
                    {genre}
                  </div>
                ))}
              </Slider>
            ) : (
              <input
                type={currentQuestion === 6 ? "text" : "text"} // Text input for the new question
                value={questions[currentQuestion].answer || ''}
                onChange={(e) => questions[currentQuestion].setAnswer(e.target.value)}
                required
              />
            )}
            <button type="submit">
              {currentQuestion < questions.length - 1 ? "Continue" : "Build My Profile"}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default CreatorTypeform;
