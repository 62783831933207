import React, { useState } from 'react';
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './Header.css'; // Ensure this line is present

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="header">
      <div className="banner">
        <a href="https://app.newgame.me" target="_blank" rel="noopener noreferrer"> {/* Wrap logo in anchor tag */}
          <img src={logo} alt="Logo" className="header-logo" />
        </a>
      </div>
      <div className="navigation">
        <a href="https://newgame.me/agency" className="nav-link">Agency</a>
        <a href="https://newgame.me/docs" target="_blank" rel="noopener noreferrer" className="nav-link">Docs</a>
      </div>
      <div className="header-right">
        <a href="./login" target="_blank" rel="noopener noreferrer" className="nav-button">Sign In</a>
      </div>
      <div className="dropdown" onClick={toggleDropdown}>
        Menu
        <div className={`dropdown-content ${dropdownOpen ? 'open' : ''}`}>
          <a href="https://newgame.me/agency" className="nav-link">Agency</a>
          <a href="https://newgame.me/docs" target="_blank" rel="noopener noreferrer" className="nav-link">Docs</a>
          <a href="./login" className="nav-button">Sign In</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
