import React, { useState } from 'react';
import { auth, database } from '../firebase'; // Adjust the path as necessary
import { signInWithEmailAndPassword } from "firebase/auth";
import { ref, get } from "firebase/database";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user data from Firebase
      const userRef = ref(database, 'users/' + user.uid);
      const snapshot = await get(userRef);
      const userData = snapshot.val();

      if (userData) {
        console.log('User logged in successfully:', user);
        // Check the signedUp property
        if (userData.signedUp) {
          // Navigate to the appropriate profile page based on userType
          if (userData.userType === 'creator') {
            navigate(`/creator-page/${user.uid}`);
          } else if (userData.userType === 'studio') {
            navigate(`/studio-page/${user.uid}`);
          } else {
            alert('User type is not recognized.');
          }
        } else {
          // Navigate to the appropriate typeform based on userType
          if (userData.userType === 'creator') {
            navigate('/creator-typeform', { state: { userId: user.uid } });
          } else if (userData.userType === 'studio') {
            navigate('/studio-typeform', { state: { userId: user.uid } });
          } else {
            alert('User type is not recognized.');
          }
        }
      } else {
        console.error('No data found for user:', user.uid);
        alert('User data not found.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Login failed: ' + error.message); // Display error message to the user
    }
  };

  return (
    <div>
      <h1>Log In</h1>
      <form onSubmit={handleLogin}>
        <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
        <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit">Log In</button>
      </form>
    </div>
  );
};

export default Login;
